import React from 'react';
import './Project.css'
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const ProjectItem = ({ projectsData }) => {
    const { title } = useParams();
    const decodedTitle = decodeURIComponent(title);
    const project = projectsData.find((project) => project.title === decodedTitle);
  return (
    <div className='Project'>
      <img className='mainImg' alt='mainimg' src={project.images[0]}></img>
      <h2>{project.title}</h2>
      <div className='ProjectData'>
        <div>
        lokalizacja:<br/><br/>
        powierzchnia:<br/><br/>
        zakres:<br/><br/>
        data:<br/><br/>
        </div>
        <div>
        &nbsp;&nbsp;&nbsp;{project.location}<br/><br/>
        &nbsp;&nbsp;&nbsp;{ReactHtmlParser(project.area)}<br/><br/>
        &nbsp;&nbsp;&nbsp;{project.range}<br/><br/>
        &nbsp;&nbsp;&nbsp;{project.date}<br/><br/>
        </div>
      </div>
      <p className='ProjectDescription'>{ReactHtmlParser(project.description)}</p>
      <div className='ProjectImages'>
        {project.images.map((image, index)=>{
          if (index !== 0){
          return(
            <img className='ProjectImage' alt={project.description} src={image}/>
          );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ProjectItem;