import React from "react";
import './AboutUs.css'

const AboutUs = () => {
    return(
        <div className="AboutUs">
            <h3>FIRMA</h3>
            Pracownia skupia się na działalności w dziedzinie nowoczesnej architektury i inteligentnych rozwiązań. Nasze projekty cechuje funkcjonalność i pomysłowość.Minimalizm i funkcjonalny design zawsze były dla nas wartościami kluczowymi. Staramy się tworzyć wyrafinowaną, ponadczasową architekturę z poszanowaniem miejsca i jego użytkowników.Tworząc projekty  indywidualnie rozpatrujemy każdy przypadek inwestycji, wykorzystując swoją  kreatywność i pasję.  Dążymy do projektów idealnie dopasowanych do potrzeb klienta, biorąc dodatkowo pod uwagę wszystkie ogniwa mające wpływ na koszty inwestycji. Na co dzień współpracujemy z konstruktorami oraz inżynierami z Chmura Biuro projektowe & nadzór budowlany, dzięki czemu projekty są nie tylko ładne wizualnie, ale również dobrze przemyślane i zoptymalizowane pod względem technicznym, co wyróżnia nas na tle innych pracowni. Na pierwszym spotkaniu z klientem, tym najważniejszym, gdzie często projekt zostaje skierowany na pewne tory, w spotkaniu zawsze uczestniczy specjalista, inżynier posiadający duże doświadczenie oraz wiedzę w zakresie budownictwa oraz technologii i ekonomiki  wznoszenia  budowli.
<br/><br/><b>Zapraszamy do kontaktu oraz na niezobowiązującą rozmowę przy kawie!</b><br/><br/>
<h3>PARTNERZY</h3>
 <div className="partners">
    <img src="/partners/chmura.jpg" alt="chmura"></img>
    <img src="/partners/knx.png" alt="knx"></img>
    <img src="/partners/fakro.jpg" alt="fakro"></img>
    <img src="/partners/scandach.png" alt="scandach"></img>
 </div>
 <br/><br/>

<h3>DANE KONTAKTOWE</h3>
<div className='contactInformation'>
                <h3>IDomy Architektura</h3>
                Kontakt:<br/>
                Kacper Śpiewak
                <br/>
                tel: 609 641 024
                <br/>
                mail: k.spiewak100@gmail.com
                <br/><br/>
                Adres:
                <br/>
                Inteligentne Domy
                <br/>
                Rzemieślnicza 13, 43-365 Wilkowice
            </div>
        </div>
    )
}

export default AboutUs