import React from "react";
import './Offer.css';

const Offer = ()=> {
    return(
        <div className="Offer">
            <h3>OFERTA</h3>
            Nasza oferta obejmuje niezwykle twórcze i spersonalizowane rozwiązania projektowe, oparte na najwyższym standardzie pracy. Podchodzimy do procesu projektowania z głębokim poczuciem odpowiedzialności i pełnym zaangażowaniem, zawsze dążąc do nietuzinkowych rozwiązań i dopasowania parametrów projektu do wymagań przyszłych użytkowników. Przy analizie lokalizacji, kontekstu, specyfiki zadania oraz jego użytkowej funkcji, proponujemy optymalne warianty projektowe. Nasze podejście opiera się na prostocie, jednak dokładności i czytelności każdego projektu, dostosowując się do każdego wyznaczonego celu. W naszej ofercie znajduje się innowacyjne i nowoczesne podejście do architektury, a także kompleksowe wsparcie na każdym etapie inwestycji, obejmujące wszystkie niezbędne zgody i pozwolenia. Dążymy do funkcjonalnych i ekonomicznych rozwiązań, staramy się tworzyć architekturę ponadczasową. Dzięki współpracy z inżynierami I ich wiedzy w dziedzinie budownictwa i materiałoznawstwa na finalnym etapie koncepcji możemy oszacować koszty inwestycji.  Co więcej konsultacje z nimi pozwalają nam również optymalizować projekt pod względem konstrukcji a co za tym idzie kalkulować i odpowiednio dobrać technologię z której powstanie dom.
<br/><br/><span>Nasza oferta obejmuje:</span><br/><br/>
<ul>
<li>
– projekty indywidualne domów jednorodzinnych
</li>
<li>
– projekty budynków pasywnych
</li>
<li>
– projekty wnętrz,
</li> 
<li>
– model 3d wnętrz [koncepcja wnętrzarska]
</li>
<li>
– projekt przebudowy
</li>
<li>
– adaptacje projektów typowych 
</li>
<li>– projekty automatyki domowej KNX</li>
<li> – inwentaryzacje budowlane</li>
<li> – nadzór autorski [Chmura Biuro projektowe & nadzór budowlany]</li>
<li> – projekty koncepcyjne </li>
<li> – wizualizacje</li>
<li> – prezentacje projektów w technologii 3d</li>
<li> – druk makiet 3d</li>
<li> – audyty i charakterystyki energetyczne</li>
</ul>

        </div>
    )
}


export default Offer