import './Footer.css'
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
const Footer = ()=> {
    return(
        <div className='footer'>
            <div className='socialsFooter'>
                <a href='https://www.facebook.com/Kacpi.Spiewak'><FacebookIcon sx={{fontSize:40}}/></a>
                <a href='https://www.instagram.com/inteligentne_domy/'><InstagramIcon sx={{fontSize:40}}/></a>
            </div>
            <img className='logo2' src='/logo.png' alt='logo'></img>
            <footer>
                © 2023 INTELIGENTNE DOMY | Wykonanie: <a href='https://webdevelopment.hype-street.pl'>&nbsp;Hype Street</a>
            </footer>
        </div>
    )
}

export default Footer