import './NavMobile.css';
import {useState, React} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from 'react-router-dom';

const NavMobile = ()=> {

    const [openMenu, setMenu] = useState(false)

    return(
        <div className='NavMobile'>
            <div ><MenuIcon className={openMenu ? 'rotate90' : ''} style={{ color:'#888', fontSize:32, transition: 'transform 1s ease', cursor:'pointer', margin:'0 0 0 15px'}} onClick={()=>setMenu((prevState)=>!prevState)}/></div>
            <div className={`NavMobileElements ${openMenu ? 'active' : ''}`}>
                <ul>
                    <Link to="/buildingsProjects"><li>projekty</li></Link>
                    <Link to="/about"><li>o nas</li></Link>
                    <Link to="/cooperation"><li>przebieg współpracy</li></Link>
                    <Link to="/offer"><li>oferta</li></Link> 
                </ul>
            </div>
        </div>
    )
}

export default NavMobile