import React from "react";
import './Home.css';
import { Link } from 'react-router-dom';
const Home = ({projectsData}) => {

    return(
            <div className="squares">
                {projectsData.map((project)=>{
                    return (
                    <Link key={project.id} to={`/project/${project.title}`} className="square" style={{backgroundImage: project.mainImg }}>
                        <span className="text">{project.title}</span>
                    </Link>
                )})}                
            </div>
    )
}

export default Home