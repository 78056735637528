import React from "react";
import MainHeader from "./MainHeader";
import { Link } from "react-router-dom";
import NavMobile from "../NavMobile/NavMobile";
import { useLocation } from "react-router-dom";
import './ProjectHeader.css';

const Header = () => {
    const { pathname } = useLocation();
        const isProjectPage = pathname.includes('/project/');

  if (isProjectPage) {
    return <ProjectHeader />;
  } else {
    return (
      <MainHeader/>
    );
  }
}

function ProjectHeader() {
  return (
    <header className="ProjectHeader">
      <a href="https://idomy.eu" ><img className="logo" src='/logo.png' alt='logo'></img></a>
          <nav>
            <ul>
              <Link to="/buildingsProjects"><li>projekty</li></Link>
              <Link to="/about"><li>o nas</li></Link>
              <Link to="/cooperation"><li>przebieg współpracy</li></Link>
              <Link to="/offer"><li>oferta</li></Link> 
            </ul>
          </nav>
          <NavMobile/>
    </header>
  );  

}

export default Header