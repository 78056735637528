import './App.css';
import Home from './Home/Home';
import AboutUs from './AboutUs/AboutUs';
import Cooperation from './cooperation/Cooperation';
import Offer from './Offer/Offer';
import Footer from './Footer/Footer';
import ProjectItem from './Project/Project';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header/Header';

function App() {

  const projectsData = [
    {
      id: 1,
      title: 'MODERNIST WILLA BIELSKO-BIAŁA',
      description: '',
      images: ['/project1/images/1.jpg', '/project1/images/2.jpg', '/project1/images/3.jpg', '/project1/images/4.jpg', '/project1/images/5.jpg', '/project1/images/6.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/1.jpg)`,
      location: 'Bielsko-Biała',
      area: '250m<sup>2</sup> z piwnicą',
      date: '2023',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 2,
      title: 'DOM Z OPASKĄ',
      description: 'Klientom zależało na domie parterowym z dachem kopertowym, jednak w nieco innym wydaniu niż standardowe domy katalogowe. Postawiliśmy tu na minimalizm w formie, odznaczający się prostym kształtem dachu o czterech połaciach, oraz horyzontalną opaską okalającą wizualnie cały budynek, w głównej mierze zadaszajacą tarasy lub przyległe do nich duże przeszklenia. Poszczególne elewacje przyjmują nowoczesny charakter, dzięki starannie dobranej kolorystyce w kolorach ciemnej szarości z jaśniejszymi betonowymi akcentami. Charakteru budynkowi nadaje i całą koncepcję spaja wyżej wspomniana biała opaska, w ciekawy sposób kontrastująca z ciemniejszymi, cofniętymi fragmentami budynku.',
      images: ['/project2/images/1.jpg', '/project2/images/2.jpg', '/project2/images/3.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/2.jpg)`,
      location: 'Skoczów',
      area: '167m<sup>2</sup> + garaż',
      date: '2023',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 3,
      title: 'HANGING BARN',
      description: '',
      images: ['/project3/images/1.jpg', '/project3/images/2.jpg', '/project3/images/3.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/3.jpg)`,
      location: 'Bielsko-Biała',
      area: '272m<sup>2</sup> + garaż',
      date: '2023',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 4,
      title: 'DOM Z NADWIESZENIAMI W KOZACH',
      description: 'Bryła budynku do dynamiczne połączenie dachu dwuspadowego ze stropodachem z charakterystycznymi wysunięciami stanowiącymi jednocześnie nadwieszenie nad dolną i górną częścią bryły. Linię wysuniętego jasnego okapu wizualnie podkreśla również kontrastowy kolor, cofnięty względem ciemniejszej ściany fasadowej. Nowoczesny charakter elewacji nadają również przeszklenia na całą wysokość fasady, które będąc odpowiednio cofnięte względem krawędzi nadwieszenia, nie będą się nadmiernie przegrzewały w cieplejsze dni.',
      images: ['/project4/images/1.jpg', '/project4/images/2.jpg', '/project4/images/3.jpg', '/project4/images/4.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/4.jpg)`,
      location: 'Kozy',
      area: '147m<sup>2</sup> + garaż',
      date: '2023',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 5,
      title: 'STODOŁA Z WCIĘCIAMI',
      description: 'Budynek swoją formą i kolorystyką idealnie wpisuje się w otoczenie sąsiadujących lasów oraz budynków zagrodowych. Na elewacji pojawił się łupek oraz deska we wnęce oplatającej taras oraz wejście. Dom dzięki prostej, minimalistycznej kubaturze, dużych przeszkleniach zorientowanych od strony południowej, grubej warstwy izolacji i energoefektywnych systemach jak pompa ciepła i wentylacja mechaniczna z odzyskiem ciepła, staje się budynkiem o charakterystyce zbliżonej do budynków pasywnych, gwarantującym bardzo niskie koszty eksploatacji. Budynek zaprojektowano w konstrukcji murowanej o przekryciu wykonanym z wiązarów dachowych firmy Scan Dach Sp. z oo. Wiązary pozwoliły na całkowite uwolnienie sufitu w części dziennej. Dodatkowo elementy wiązarów widoczne są również z poziomu pokoi dzieci [zlokalizowanych od strony tarasu], dzięki czemu pokoje można w każdej chwili powiększyć o antresolę. Rozpiętość i elastyczność w kształtowaniu konstrukcji za pomocą wiązarów pozwoliła dodatkowo na wytworzenie charakterystycznego wcięcia w bryle budynku.',
      images: ['/project5/images/1.jpg', '/project5/images/2.jpg', '/project5/images/3.jpg', '/project5/images/4.jpg', '/project5/images/5.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/5.jpg)`,
      location: 'Mazańcowice',
      area: '168m<sup>2</sup> + garaż',
      date: '2023',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 6,
      title: 'PROJEKT ELEWACJI HALI W  ŻYWCU',
      description: '',
      images: ['/project6/images/1.jpg', '/project6/images/2.jpg', '/project6/images/3.jpg', '/project6/images/4.jpg', '/project6/images/5.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/6.jpg)`,
      location: 'Żywiec',
      area: '-',
      date: '2023',
      range: 'projekt elewacji'
    },
    {
      id: 7,
      title: 'BIAŁY DOM -BIELKSO BIAŁA',
      description: '',
      images: ['/project7/images/1.jpg', '/project7/images/2.jpg', '/project7/images/3.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/7.jpg)`,
      location: 'Bielsko-Biala',
      area: '165m<sup>2</sup> + garaż',
      date: '2023',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 8,
      title: 'PRZEBUDOWA BUDYNKU W Bestwinie',
      description: 'Projekt przebudowy zakładał nadbudowę poddasza użytkowego wraz ze zmianą wewnętrznego układu funkcjonalnego Część przyziemia stanowi przestrzeń garażowo-warsztatową z pomieszczeniem towarzyszącym mogącym zostać zaadaptowane jako siłownia bądź zaplecze warsztatu. Część dzienną wyniesiono na drugą kondygnację. Kształtuje ją otwarty układ kuchni z jadalnią oraz salonem z dostępem do tarasu będącym użytkowym stropodachem nad częścią warsztatu. Kondygnację piętra ze względu na lekkość konstrukcji oraz średnie warunki nośne gleby, domknięto stropem RECTOR. Całość wieńczy użytkowe poddasze o przeznaczeniu sypialnym.',
      images: ['/project8/images/1.jpg', '/project8/images/2.jpg', '/project8/images/3.jpg','/project8/images/4.jpg', '/project8/images/5.jpg', '/project8/images/6.jpg', '/project8/images/7.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/8.jpg)`,
      location: 'Bestwina',
      area: '-',
      date: '2022',
      range: 'projekt przebudowy'
    },
    {
      id: 9,
      title: 'DOM Z PŁASKIM DACHEM W RYBNIKU',
      description: 'Na życzenie klientów powstała koncepcja nowoczesnego budynku z płaskim dachem. Działka pod inwestycję położona jest na śląsku. Ze względu na dość duże zanieczyszczenie powietrza w tym regionie a co za tym idzie ryzyko zniszczenia jasnych elewacji, postanowiliśmy ubrać bryłę budynku w ciemną, matową strukturę z płyt wentylowanych. Sam parter częściowo pokryty został szarym tynkiem, dzięki temu duży budynek zyskał trochę lekkości. Wrażenie to wspomaga również nadwieszona część sypialna piętra, która dodatkowo zadasza strefę wejściową bezpośrednio przed budynkiem. Dom ma mocno rozbudowany zakres w programie funkcjonalnym, posiada  między innymi dużą otwartą przestrzeń dzienną, siłownię z sauną i kilka sypialni na piętrze. ',
      images: ['/project9/images/1.jpg', '/project9/images/2.jpg', '/project9/images/3.jpg', '/project9/images/4.jpg', '/project9/images/5.jpg', '/project9/images/6.jpg', '/project9/images/7.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/9.jpg)`,
      location: 'Rybnik',
      area: '320m<sup>2</sup> z garażem',
      date: '2022',
      range: 'projekt koncepcyjny'
    },
    {
      id: 10,
      title: 'SALE KONFERENCYJNE ŚRUBENA UNIA S.A',
      description: '',
      images: ['/project10/images/1.jpg', '/project10/images/2.jpg', '/project10/images/3.jpg', '/project10/images/4.jpg', '/project10/images/5.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/10.jpg)`,
      location: 'Żywiec',
      area: '-',
      date: '2021',
      range: 'Projekt wnętrz'
    },
    {
      id: 11,
      title: 'PRZEBUDOWA BUDYNKU GOSPODARCZEGO NA DOM MIESZKALNY',
      description: 'Największym wyzwaniem projektowym stał się niewielki metraż pomieszczenia mającego stanowić pokój dzienny z salonem i kuchnią. Udało się go troszkę powiększyć i doświetlić za pomocą ogrodu zimowego. Cały układ wieńczy przestronna sypialnia z łazienką i niewielkim pomieszczeniem technicznym. Nietypowym rozwiązaniem są tu kacze schody od firmy DOLLE, zajmujące możliwie mało miejsca i pasujące do niewielkich otworów w stropie pod wyjście na wyższą kondygnację. Poddasze w tym wypadku zaplanowano jako część gościnną z osobnym węzłem łazienkowym.',
      images: ['/project11/images/1.jpg', '/project11/images/2.jpg', '/project11/images/3.jpg', '/project11/images/4.jpg', '/project11/images/5.jpg', '/project11/images/6.jpg', '/project11/images/7.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/11.jpg)`,
      location: 'okolice Bielska-Białej',
      area: '-',
      date: '2022',
      range: 'Projekt na zgłoszenie'
    },
    {
      id: 12,
      title: 'L-SHAPED BARN',
      description: 'Głównym życzeniem inwestorów był dom w stylu nowoczesnej stodoły ukształtowany w formie litery "L". Zadania nie ułatwiała dość specyficzna, wąska działka. Jej atutem natomiast był piękny zaciszny krajobraz powiatu cieszyńskiego, co utwierdziło nas w przekonaniu że zaprojektowanie tam budynku na kształt stodoły pokrytej drewnem na elewacji, będzie w pewien sposób ukłonem dla kontekstu tego miejsca<br/><br/>Jadalnia z kuchnią stanowią jedną dużą przestrzeń. Duże przeszklenia sprawiają, że zaciera się granica pomiędzy wnętrzem i zewnętrzem. Ogród zdaje się wchodzić do środka domu.Klientom zależało by wnętrze zostało zaprojektowane w stylu skandynawskim. Z tego względu dominują tu szarości, biele i odcienie jasnego norweskiego drewna. Uwagę skupia również marmurowa wyspa, leżącą w samym centrum domu, nieotoczona żadnymi ścianami staje się również punktem obserwacji różnych aktywności domowych.',
      images: ['/project12/images/1.jpg', '/project12/images/2.jpg', '/project12/images/3.jpg', '/project12/images/4.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/12.jpg)`,
      location: 'Dzięgielów',
      area: '150m<sup>2</sup> z garażem',
      date: '2021',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 13,
      title: 'DWIE STODOŁY WICHROWE WZGÓRZE',
      description: '',
      images: ['/project13/images/1.jpg', '/project13/images/2.jpg', '/project13/images/3.jpg', '/project13/images/4.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/13.jpg)`,
      location: 'Wichrowe-Wzgórze',
      area: '180m<sup>2</sup> z garażem',
      date: '2021',
      range: 'Koncepcja, projekt elewacji'
    },
    {
      id: 14,
      title: 'BUDYNEK DWULOKALOWY',
      description: '',
      images: ['/project14/images/1.jpg', '/project14/images/2.jpg', '/project14/images/3.jpg', '/project14/images/4.jpg', '/project14/images/5.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/14.jpg)`,
      location: '-',
      area: '-',
      date: '2021',
      range: 'Koncepcja'
    },
    {
      id: 15,
      title: 'PARTERÓWKA GILOWICE',
      description: 'Chcieliśmy by dom swoją formą chociaż trochę wyróżniał się na tle typowych "parterówek" i szedł z duchem nowoczesnego budownictwa. Efekt ten udało się uzyskać dzięki dość spektakularnemu nadwieszeniu nad strefą wejściową i tarasem. Całość dopełnia efektowny pas gzymsu, który oplata cały budynek i kontrastuje z ciemniejszym tynkiem imitującym beton na elewacji. Część dzienna z salonem i jadalnią, dużymi przeszkleniami otwiera się na ogród, jest zorientowana w stronę atrakcyjnych widoków, a zadaszony taras stanowi przedłużenie tej części domu.',
      images: ['/project15/images/1.jpg', '/project15/images/2.jpg', '/project15/images/3.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/15.jpg)`,
      location: 'Gilowice',
      area: '180m<sup>2</sup> z garażem',
      date: '2021',
      range: 'projekt do pozwolenia na budowę'
    },
    {
      id: 16,
      title: 'DOM W LIPOWEJ',
      description: 'Inwestorzy przyszli do nas z pragnieniem zamieszkania w przyszłości w dużej villi/rezydencji.Sam projekt ma powstać w podgórskiej miejscowości Lipowa, a działka roztacza się na piękne o każdej porze roku góry Beskidu żywieckiego. Całość powstała po długich konsultacjach z klientami i jest kompromisem pomiędzy rezydencjonalnym charakterem, jaki chcieli osiągnąć inwestorzy a popularnym w tym regionie domem z dachem dwuspadowym i drewnianą elewacją.Nowoczesny charakter architektury zapewniają tu duże narożne przeszklenia i ciekawe połączenie materiałów wykończeniowych czyli drewna, betonu architektonicznego i blasze na rąbek.',
      images: ['/project16/images/1.jpg', '/project16/images/2.jpg', '/project16/images/3.jpg'],
      mainImg: `url(${process.env.PUBLIC_URL}/squares/16.jpg)`,
      location: 'Lipowa',
      area: '220m<sup>2</sup> z garażem',
      date: '2020',
      range: 'projekt do pozwolenia na budowę'
    },
]

  return (
    <Router>
      <div className='app'>
        <Header/>
      </div>
      <Routes>
        <Route exact path="/" element={<Home projectsData={projectsData}/>} />
        <Route path="/buildingsProjects" element={<Home projectsData={projectsData} />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/cooperation" element={<Cooperation />} />
        <Route path="/offer" element={<Offer/>} />
        <Route
          path="/project/:title"
          element={<ProjectItem projectsData={projectsData}/>}
        />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
