import React from "react";
import './Cooperation.css'

const Cooperation = () => {
    return(
        <div className="Cooperation">
            <h3>PRZEBIEG WSPÓŁPRACY</h3>
<span>WIZYTA NA DZIAŁCE</span><br/><br/>
Nasz proces rozpoczynamy od odwiedzenia działki. W tym etapie przeprowadzamy dokumentację fotograficzną oraz analizujemy różne aspekty. Badamy, w jakich kierunkach leży działka, jakie jest nasłonecznienie, jaki jest otaczający ją krajobraz oraz jej topografia. Dokładnie przyglądamy się charakterystyce terenu, identyfikujemy mocne strony i słabe punkty lokalizacji. W trakcie wizyty na działce, masz także okazję wskazać nam istotne elementy otoczenia oraz wstępnie określić swoje preferencje, na przykład co do usytuowania tarasu, tak aby cieszyć się określonym widokiem. Wizyta na działce pomoże nam zrozumieć i poczuć kontekst miejsca, w którym projektujemy. Na działkę wybieramy się również na finalnym etapie koncepcji, aby wstępnie wytyczyć budynek i sprawdzić czy wszystko gra w terenie.
<div className="exterrior">
    <img src="/interrior/1.jpg" alt="wnętrze 1"></img>
    <img src="/interrior/2.jpg" alt="wnętrze 2"></img>
</div>
<br/><br/>
<span>KONCEPCJA PROJEKTOWA</span><br/><br/>
To najważniejszy etap projektu, który finalnie  prezentujemy na fotorealistycznych wizualizacjach. Tworzenie koncepcji nie jest łatwe, wymaga od nas wielu analiz, eksperymentów, wstępnych szkiców, budowania szkicowych modeli 3D, żeby zobaczyć jak budynek układa się na działce w różnych kształtach i zastanawiania się, co w danym miejscu, dla danego klienta będzie najlepsze. Ten etap wymaga pełnej współpracy z Inwestorem, bez limitu pomysłów i przemyśleń, bo tu odbywa się magia tworzenia, która często objawia się przedstawieniem nawet kilku ciekawych rozwiązań projektowych.  Na koniec uzyskujemy trójwymiarowy projekt, który pozwoli zrozumieć zaprojektowany obiekt nawet osobom bez wyobraźni przestrzennej. 
<div className="video">
    <video  autoPlay loop muted preload="none">
    <source src="video.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
    </video>
</div>
<div className="interrior">
    <img src="/interrior/3.jpg" alt="wnętrze 1"></img>
    <img src="/interrior/4.jpg" alt="wnętrze 2"></img>
</div>
<br/>
<span>PROJEKT ARCHITEKTONICZNY DO POZWOLENIA NA BUDOWĘ</span><br/><br/>
Na tym etapie projekt jest uszczegóławiany w stosunku do koncepcji, określane są rozwiązania techniczno-materiałowe, konstrukcyjne.Na tym etapie każdy szczegół projektu jest przemyślany pod kątem możliwości realizacji, dostępności i kosztów. W zależności od uwarunkowań Inwestycji projekt budowlany składany jest do urzędu trybem zgłoszenia lub pozwolenia na budowę. Na życzenie Inwestora organizujemy niezbędne mapy do celów projektowych, badania gruntu. W procesie uzyskania pozwolenia na budowę jesteśmy reprezentantami Inwestora na wszystkich płaszczyznach, pełnomocnikiem który dba o powodzenie Inwestycji i organizację wszystkich formalności. 
PROJEKT TECHNICZNY
Projektowanie wieńczy część techniczna projektu. Zawiera ona projekt konstrukcji, zestawienia materiałowe oraz niezbędne rysunki instalacji aby możliwe było wystartowanie z budową. Tą częścią projektu zwykle zajmują się inżynierowie i konstruktorzy z biura  Chmura Biuro projektowe & nadzór budowlany. Przygotowując wycenę zawsze uwzględniamy zrobienie projektu technicznego, po to aby klient dostał kompleksowo, gotowy produkt, pozwalający ruszyć z budową.
        </div>
    )
}

export default Cooperation